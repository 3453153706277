import React from "react";

const educationContent = [
  {
    year: "2020",
    degree: "EXTRA Certification",
    institute: "Mikrotik, SOLOLEARN, Microsoft",
    details: `Got extra certification from Mikrotik MTCNA, Microsoft Certification & Sololearn Certification.`,
  },
  {
    year: "2017",
    degree: "ENGINEERING DEGREE",
    institute: "Pokhara Engineering College",
    details: `Completed engineering degree for this college.`,
  },
  {
    year: "2012",
    degree: "INTERMEDIATE LEVEL",
    institute: "ADARSH HIGHER SECONDARY SCHOOL",
    details: `Completed the ISc level for higher studies to get degree`,
  },
  {
    year: "2010",
    degree: "SLC",
    institute: "JAY BAGESHWORI HIGHER SECONDARY SCHOOL",
    details: `Completed the iron gate of school level to get higher study.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
