import React from "react";

function getAge(birthYear) {
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var age = currentYear - birthYear;
  return age;
}
var calculatedAge = getAge("1993");

const personalInfoContent = [
  { meta: "first name", metaInfo: "Rupesh" },
  { meta: "last name", metaInfo: "Chaudhary" },
  { meta: "Age", metaInfo: calculatedAge + " Years" },
  { meta: "Nationality", metaInfo: "Neplease" },
  { meta: "Freelance", metaInfo: "Available" },
  { meta: "Address", metaInfo: "Ganeshpur -04 Nepalgunj" },
  { meta: "phone", metaInfo: "+9779802004917" },
  { meta: "Email", metaInfo: "unsined.raymon@gmail.com" },
  { meta: "Whatsapp", metaInfo: "+9779824607241" },
  { meta: "langages", metaInfo: "Nepali, Hindi, Tharu, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
